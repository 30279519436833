// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-templates-article-view-js": () => import("./../../../src/templates/articleView.js" /* webpackChunkName: "component---src-templates-article-view-js" */),
  "component---src-templates-page-view-js": () => import("./../../../src/templates/pageView.js" /* webpackChunkName: "component---src-templates-page-view-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */)
}

